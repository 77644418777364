<template>
    <div class="MobCoupon">
        手机版暂为开放
    </div>
</template>

<script>
export default {
    name:"MobCoupon"
}
</script>